<template>
  <v-footer padless>
    <v-card flat tile class="flex background-home">
      <v-card-text class="py-2 text-center white--text">
        <v-btn small text class="btn-link white--text" :href="links.privacy" target="_blank">
          Aviso de privacidad
        </v-btn> |
        <v-btn small text class="btn-link white--text" :href="links.terms" target="_blank">
          Términos y condiciones
        </v-btn>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'mainFooter',
  data: () => ({
    mark: process.env.VUE_APP_MARK,
    links: {
      webpage: process.env.VUE_APP_WEBPAGE,
      privacy: process.env.VUE_APP_LINK_PRIVACY,
      terms: process.env.VUE_APP_LINK_TERMS
    }
  })
}
</script>

<style scoped>
  .background-home {
    background-color: #5271ff !important;
  }
  .btn-link {
    text-transform: none;
  }
</style>
