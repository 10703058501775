<template>
  <v-app id="login">
    <v-main>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col cols="12" md="5">
            <v-img :src="logo" max-height="90" contain/>
          </v-col>
          <v-col cols="1" class="d-none d-md-flex">
            <div class="vl"></div>
          </v-col>
          <v-col cols="12" md="6">
            <template v-if="state == 0">
              <v-card class="mx-auto" elevation="5" max-width="500">
                <v-card-title class="justify-center mb-3 main-title">
                  Inicio de sesión
                </v-card-title>
                <v-card-text>
                  <v-form ref="formLogin" lazy-validation @keyup.enter.native="login()">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="forms.login.email" label="Email" :rules="rules.email" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="forms.login.password" type="password" label="Contraseña" :rules="rules.password" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form> 
                  <v-row class="justify-center">
                    <v-btn text small rounded elevation="0" :disabled="loading" @click="updateState(2)">¿Olvidaste tu contraseña?</v-btn>
                  </v-row>
                  <v-row align="center" justify="space-around" class="pa-3 pt-4">
                    <v-btn large rounded color="white" :disabled="loading" @click="updateState(1)">Registrarse</v-btn>
                    <v-btn large rounded color="secondary" class="black--text" :loading="loading" @click="login()">Entrar</v-btn>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
            <template v-if="state == 1">
              <v-card class="mx-auto" elevation="5" max-width="500">
                <v-card-title class="justify-center mb-3 main-title">
                  Registro de usuario
                </v-card-title>
                <v-card-text>
                  <v-form ref="formRegister" lazy-validation @submit.prevent>
                    <v-row>
                      <v-col class="py-1" cols="12">
                        <v-text-field dense v-model.trim="forms.register.email" label="Email" :rules="rules.email" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-1" cols="6">
                        <v-text-field dense v-model="forms.register.password" type="password" label="Contraseña" :rules="rules.password2" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                      </v-col>
                      <v-col class="py-1" cols="6">
                        <v-text-field dense v-model="forms.register.confirmPassword" type="password" label="Confirmar Contraseña" :rules="rules.password" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-1" cols="12">
                        <v-text-field dense v-model.trim="forms.register.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-1" cols="6">
                        <v-text-field dense v-model.trim="forms.register.apellido_paterno" label="Apellido Paterno" :rules="rules.apellido_paterno" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                      </v-col>
                      <v-col class="py-1" cols="6">
                        <v-text-field dense v-model.trim="forms.register.apellido_materno" label="Apellido Materno" :rules="rules.apellido_materno" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-1" cols="6">
                        <v-text-field dense v-model.trim="forms.register.telefono" label="Teléfono" :rules="rules.telefono" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                      </v-col>
                      <v-col class="py-1" cols="6">
                        <v-text-field dense v-model.trim="forms.register.empresa" label="Empresa" :rules="rules.empresa" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-1" cols="12">
                        <v-autocomplete dense v-model="forms.register.sector_profesional" :items="catalogues.sectores_profesionales" item-text="nombre" item-value="id" label="Sector profesional" :rules="rules.sector_profesional" :disabled="loading" outlined required autocomplete="off"></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0" cols="12">
                        <v-checkbox dense v-model="forms.register.legal" :disabled="loading" color="primary">
                          <template v-slot:label>
                            <div>He leído y acepto el <a target="_blank" :href="links.privacy" @click.stop>Aviso de privacidad</a> y los <a target="_blank" :href="links.terms" @click.stop>Términos y condiciones.</a></div>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row align="center" justify="space-around" class="pa-3">
                    <v-btn large rounded color="white" :disabled="loading" @click="updateState(0)">Volver</v-btn>
                    <v-btn large rounded color="secondary" class="black--text" :disabled="!forms.register.legal" :loading="loading" @click="register()">Continuar</v-btn>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
            <template v-if="state == 2">
              <v-card class="mx-auto" elevation="5" max-width="500">
                <v-card-title class="justify-center mb-3 main-title">
                  Recuperar contraseña
                </v-card-title>
                <v-card-text>
                  <v-form ref="formForgot" lazy-validation @submit.prevent>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="forms.forgot.email" label="Email" :rules="rules.email" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row align="center" justify="space-around" class="pa-3">
                    <v-btn large rounded color="white" :disabled="loading" @click="updateState(0)">Volver</v-btn>
                    <v-btn large rounded color="secondary" class="black--text" :loading="loading" @click="recovery()">Continuar</v-btn>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </v-row>
        <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
          {{ msgSuccess }}
        </v-snackbar>
        <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
          {{ msgError }}
        </v-snackbar>
      </v-container>
    </v-main>
    <main-footer/>
  </v-app>
</template>

<script>
import mainFooter from '@/components/Footer'

export default {
  name: 'login',
  components: { mainFooter },
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    name: process.env.VUE_APP_NAME,
    logo: require('@/assets/logo.png'),
    paths: {
      login: '/pub/login',
      recoverPassword: '/pub/recover/password',
      registerUser: '/pub/register/user',
      professionalSector: '/pub/professionalSector'
    },
    links: {
      privacy: process.env.VUE_APP_LINK_PRIVACY,
      terms: process.env.VUE_APP_LINK_TERMS
    },
    loading: false,
    error: false,
    msgError: '',
    success: false,
    msgSuccess: '',
    state: 0,
    catalogues: {
      sectores_profesionales: []
    },
    forms: {
      login: {
        email: '',
        password: ''
      },
      forgot: {
        email: ''
      },
      register: {
        email: '',
        password: '',
        confirmPassword: '',
        nombre: '',
        empresa: '',
        apellido_paterno: '',
        apellido_materno: '',
        telefono: '',
        sector_profesional: '',
        legal: false
      }
    },
    rules: {
      email: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 320) || 'El campo excede la longitud máxima',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'El campo es inválido'
      ],
      password: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length >= 8) || '',
        v => (v && v.length <= 60) || '',
        v => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-zñÑ\d@$!%*#?&]{8,}$/.test(v) || ''
      ],
      password2: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length >= 8) || 'La contraseña debe contener mínimo 8 caracteres alfanuméricos, incluyendo al menos una letra y un número',
        v => (v && v.length <= 60) || 'La contraseña excede la longitud máxima',
        v => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-zñÑ\d@$!%*#?&]{8,}$/.test(v) || 'La contraseña debe contener mínimo 8 caracteres, incluyendo al menos una letra y un número'
      ],
      nombre: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
      ],
      apellido_paterno: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
      ],
      apellido_materno: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
      ],
      telefono: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 15) || 'El campo excede la longitud máxima'
      ],
      empresa: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
      ],
      sector_profesional: [
        v => !!v || 'El campo es requerido'
      ]
    }
  }),
  mounted() {
    this.getCatalogues()
    this.checkParams()
  },
  methods: {
    async getCatalogues() {
      const sector = await this.axios.get(this.host + this.paths.professionalSector)
      if (sector.data.data) {
        this.catalogues.sectores_profesionales = sector.data.data
      }
    },
    checkParams() {
      if (['#signup', '#_signup', '#register', '#registro'].indexOf(this.$route.hash) >= 0) {
        this.state = 1
      }
    },
    updateState(id) {
      if (this.state == 0) {
        this.$refs.formLogin.reset() 
      }
      else if (this.state == 1) {
        this.$refs.formRegister.reset()
      }
      else if (this.state == 2) {
        this.$refs.formForgot.reset()
      }
      for (let key in this.forms.login) {
        this.forms.login[key] = ''
      }
      for (let key in this.forms.forgot) {
        this.forms.forgot[key] = ''
      }
      for (let key in this.forms.register) {
        if (key !== 'legal') {
          this.forms.register[key] = ''
        }
        else {
          this.forms.register[key] = false
        }
      }
      this.state = id
    },
    login() {
      if (this.$refs.formLogin.validate()) {
        this.loading = true
        let data = {
          username: this.forms.login.email,
          password: this.forms.login.password
        }
        this.axios.post(this.host + this.paths.login, { data })
          .then(response => {
            let res = response.data
            this.$session.start()
            this.$session.set('jwt', res.token)
            this.$router.push('/home')
          })
          .catch(error => {
            this.error = true
            if (error.response) {
              this.msgError = error.response.data.message
            }
            else {
              this.msgError = 'Servicio no disponible. Intente más tarde'
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'Los datos son incorrectos'
      }
    },
    register() {
      if (this.$refs.formRegister.validate()) {
        if (this.forms.register.password === this.forms.register.confirmPassword) {
          if (this.forms.register.legal) {
            this.loading = true
            let data = JSON.parse(JSON.stringify(this.forms.register))
            delete data.confirmPassword
            this.axios.post(this.host + this.paths.registerUser, { data })
              .then(response => {
                let res = response.data
                this.updateState(0)
                this.success = true
                this.msgSuccess = 'Usuario registrado exitosamente'
              })
              .catch(error => {
                this.error = true
                this.msgError = error.response.data.message || 'Servicio no disponible'
              })
              .finally(() => {
                this.loading = false
              })
          }
          else {
            this.error = true
            this.msgError = 'No se han aceptado el Aviso de privacidad y los Términos y condiciones'
          }
        }
        else {
          this.error = true
          this.msgError = 'Las contraseñas no coinciden'
        }
      }
    },
    recovery() {
      if (this.$refs.formForgot.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.forgot))
        this.axios.post(this.host + this.paths.recoverPassword, { data })
          .then(response => {
            this.updateState(0)
            this.success = true
            this.msgSuccess = "Solicitud enviada exitosamente"
          })
          .catch(error => {})
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    }
  }
}
</script>

<style scoped>
  .vl {
    border-left: 1px solid rgb(255, 255, 255);
    height: 300px;
  }
  .main-title {
    font-size: 31.5px;
    color: #5271ff;
  }
</style>
